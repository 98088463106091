import { useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";

const localEnvironment = process.env.REACT_APP_FRONTEND_LOCAL_ENV === "true";
const stagingRoute = process.env.REACT_APP_FRONTEND_STAGING_ENV === "true";
const productionEnvironment =
  process.env.REACT_APP_FRONTEND_PROD_ENV === "true";
const secretKey = "secretKey123secretKey";

function getCookie(cookieName) {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}

export const setCookieAndLocalStorage = (key, value) => {
  // If we have localEnvironment sets to true then it will store key on localstorage otherwise on cookie(for server deployment)
  localEnvironment
    ? localStorage.setItem(key, value)
    : (document.cookie = `${key}=${value}; domain=.websiteranking.ai; path=/; Secure; SameSite=None`);
};

export const getToken = (key) => {
  // If we have localEnvironment sets to true then it will get key from localstorage otherwise from cookie(for server deployment)
  const token = localEnvironment ? localStorage.getItem(key) : getCookie(key);
  return token;
};

export const setCookieAndLocalStorageObject = (key, value) => {
  const encryptedValue = CryptoJS.AES.encrypt(
    JSON.stringify(value),
    secretKey
  ).toString();
  localEnvironment
    ? localStorage.setItem(key, encryptedValue)
    : (document.cookie = `${key}=${encryptedValue}; domain=.websiteranking.ai; path=/; Secure; SameSite=None`);
};

export const getCookieAndLocalStorageObject = (key) => {
  const encryptedValue = localEnvironment
    ? localStorage.getItem(key)
    : getCookie(key);

  if (encryptedValue) {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
    const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedValue);
  }
  return null;
};

export const deleteToken = (key) => {
  // If we have localEnvironment sets to true then it will delete key from localstorage otherwise from cookie(for server deployment)
  localEnvironment
    ? localStorage.removeItem(key)
    : (document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.websiteranking.ai; path=/; Secure; SameSite=None;`);
};

// For Production
export const parentRoute = () => {
  if (localEnvironment) return "http://localhost:3001";
  else if (stagingRoute) return "https://staging.websiteranking.ai";
  else if (productionEnvironment) return "https://websiteranking.ai";
  else return;
};
export const reactGA = () => {
  return productionEnvironment ? `G-2HVF90ZYBT` : `heythereisnokeyavailable`;
};

export const useLocationParams = () => {
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const secondParam = pathParts[2];
  if (secondParam === "instagram.com" || secondParam === "amazon.com") {
    return true;
  } else {
    return false;
  }
};
